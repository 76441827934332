import { TextInput } from '@bit/ume.design-kit.ui'
import { Checkbox, Col, Popover, Row } from 'antd'
import { Box } from 'grommet'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { validate_celular, validate_cnpj } from '../../common/Validators'
import { BlockPicker } from '../../molecules/RetailersPage/BlockPicker'
import DetailsCard, { IDetailsCardData } from '../../molecules/RetailersPage/DetailsCard'
import DropDownInput from '../../molecules/RetailersPage/DropDownInput'
import EditRetailerBlockModal from '../../molecules/RetailersPage/EditRetailerBlockModal'
import EditRetailerIssuerModal from '../../molecules/RetailersPage/EditRetailerIssuerModal'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import { CardStyle } from '../../molecules/RetailersPage/styles'
import { StyledButton } from '../../pages/Retailers/style'
import { OperatorsSliceReducer } from '../../redux/reducers/operators/operators.reducer'
import { EditRetailerCategoriesPayload, EditRetailerIssuerPayload, RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { StoresSliceReducer } from '../../redux/reducers/stores/stores.reducer'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import {
  BankAccountType,
  DebtFundingPackResponse,
  IPutRetailerFinalBankAccount,
  IPutRetailerIntermediaryBankAccount,
  IRetailerCategoryReponse,
  IRetailerResponse,
  IssuerResponse,
  RETAILER_STATUS
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { formatCNPJ, formatPhoneNumber } from '../../utils'
import FormModal from './FormModal'
import {
  GreenStatus,
  RedStatus,
  SectionTitleStyle,
  SmallLoadingIcon,
  StyledTextRetailerName,
  SubSectionTitleStyle,
} from './styles'

interface IRetailerTabProps extends RouteComponentProps {
  retailer: IRetailerResponse
  categories: IRetailerCategoryReponse[]
  blocks: DebtFundingPackResponse[]
  resetStores: () => {}
  resetOperators: () => {}
  fetchRetailer: (retailerId: string) => void
  resetRetailerOriginationParameters: () => void
  updateRetailerStatus: (retailerId: string) => void
  isUpdatingRetailerStatus: boolean
  isPutRetailerLogoLoading: boolean
  putRetailerLogo: (retailerId: string, logo: File) => {}

  editRetailerCategories: (payload: EditRetailerCategoriesPayload) => {}
  editRetailerCategoriesModalOpen: () => {}
  editRetailerCategoriesModalClose: () => {}
  isEditRetailerCategoriesLoading: boolean
  isEditRetailerCategoriesModalOpen: boolean
  closeEditRetailerBlockModal: boolean
  
  editRetailerIssuer: (payload: EditRetailerIssuerPayload) => {}
  resetEditRetailerIssuerState: () => {}
  isEditRetailerIssuerLoading: boolean
  closeEditRetailerIssuerModal: boolean
  
  fetchIssuers: () => {}
  issuers?: IssuerResponse[]
  isLoadingIssuers: boolean
}

interface IRetailerTabState {
  intermediaryBankAccountBankCode: string
  intermediaryBankAccountAccountType: string
  intermediaryBankAccountAgencyCode: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode: string
  intermediaryBankAccountDigit: string
  depositAccountOwnerDocument: string
  depositAccountOwnerName: string
  depositAccountOwnerPhoneNumber: string
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
  intermediaryBankAccountBankCodeErrorIntent: boolean
  intermediaryBankAccountAgencyCodeErrorIntent: boolean
  intermediaryBankAccountCodeErrorIntent: boolean
  intermediaryBankAccountDigitErrorIntent: boolean
  depositAccountOwnerDocumentErrorIntent: boolean
  depositAccountOwnerNameErrorIntent: boolean
  depositAccountOwnerPhoneNumberErrorIntent: boolean
  finalBankAccountBankCodeErrorIntent: boolean
  finalBankAccountAgencyCodeErrorIntent: boolean
  finalBankAccountCodeErrorIntent: boolean
  finalBankAccountDigitErrorIntent: boolean
  isCreatingIntermediaryBankAccount: boolean
  isCreatingFinalBankAccount: boolean
  isLoadingSaveFinalBankAccountButton: boolean
  isLoadingSaveIntermediaryBankAccountButton: boolean
  isRetailerStatusModalOpen: boolean
  retailerSelectedCategoriesIds: string[]
  enableEditBlock: boolean
  debtFundingPack?: DebtFundingPackResponse
  isDebtFundingPackModalOpen?: boolean
  retailerLogo?: string,
  enableEditIssuer: boolean
  newIssuer?: string
  isIssuerModalOpen?: boolean
}

const initialState: IRetailerTabState = {
  intermediaryBankAccountBankCode: '',
  intermediaryBankAccountAccountType: 'Corrente',
  intermediaryBankAccountAgencyCode: '',
  intermediaryBankAccountAgencyDigit: '',
  intermediaryBankAccountCode: '',
  intermediaryBankAccountDigit: '',
  depositAccountOwnerDocument: '',
  depositAccountOwnerName: '',
  depositAccountOwnerPhoneNumber: '',
  finalBankAccountBankCode: '',
  finalBankAccountAccountType: 'Corrente',
  finalBankAccountAgencyCode: '',
  finalBankAccountAgencyDigit: '',
  finalBankAccountCode: '',
  finalBankAccountDigit: '',
  intermediaryBankAccountBankCodeErrorIntent: false,
  intermediaryBankAccountAgencyCodeErrorIntent: false,
  intermediaryBankAccountCodeErrorIntent: false,
  intermediaryBankAccountDigitErrorIntent: false,
  depositAccountOwnerDocumentErrorIntent: false,
  depositAccountOwnerNameErrorIntent: false,
  depositAccountOwnerPhoneNumberErrorIntent: false,
  finalBankAccountBankCodeErrorIntent: false,
  finalBankAccountAgencyCodeErrorIntent: false,
  finalBankAccountCodeErrorIntent: false,
  finalBankAccountDigitErrorIntent: false,
  isCreatingIntermediaryBankAccount: false,
  isCreatingFinalBankAccount: false,
  isLoadingSaveFinalBankAccountButton: false,
  isLoadingSaveIntermediaryBankAccountButton: false,
  isRetailerStatusModalOpen: false,
  retailerSelectedCategoriesIds: [],
  enableEditBlock: false,
  retailerLogo: undefined,
  enableEditIssuer: false,
  newIssuer: undefined,
  isIssuerModalOpen: false,
}

const ACCOUNT_TYPES: any = {
  '1': 'Poupança',
  '2': 'Corrente',
}

const CnpjMaskInput = [
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '/' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
]

const CellphoneMaskInput = [
  { fixed: '(' },
  {
    length: 1,
    regexp: /[1-9]/,
    placeholder: '0',
  },
  {
    length: 1,
    regexp: /\d/,
    placeholder: '0',
  },
  { fixed: ') ' },
  {
    length: 1,
    regexp: /[5-9]/,
    placeholder: '0',
  },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
]

const DigitMaskInput = [
  {
    length: 1,
    regexp: /\d/,
    placeholder: '0',
  },
]

const AgencyMaskInput = [
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
]

const NumberMaskInput = [
  {
    regexp: /\d/,
  },
]

class RetailerTab extends React.Component<IRetailerTabProps, IRetailerTabState> {
  constructor(props: IRetailerTabProps) {
    super(props)
    this.state = initialState
  }

  componentDidMount = () => {
    if(!this.props.issuers && !this.props.isLoadingIssuers) {
      this.props.fetchIssuers()
    }
  }

  componentDidUpdate = (prevProps: IRetailerTabProps) => {
    if (
      this.props.closeEditRetailerBlockModal &&
      prevProps.closeEditRetailerBlockModal !== this.props.closeEditRetailerBlockModal
    ) {
      this.closeRetailerBlockEditionModalModal()
      this.disableRetailerBlockEdition()
    }

    if (
      this.props.closeEditRetailerIssuerModal &&
      prevProps.closeEditRetailerIssuerModal !== this.props.closeEditRetailerIssuerModal
    ) {
      this.closeRetailerIssuerEditionModal()
      this.disableRetailerIssuerEdition()
      this.props.resetEditRetailerIssuerState()
    }

    // If a new retailer is selected, reset the stores and operators reducers
    if (prevProps.retailer !== this.props.retailer && this.props.retailer) {
      this.props.resetOperators()
      this.props.resetStores()
      this.props.resetRetailerOriginationParameters()
      this.loadLogo()
      return
    }
  }

  loadLogo = async () => {
    const { retailer } = this.props;
    if (retailer) {
      try {
        const retailerLogo = await bffBackofficeApiService.retailers.getRetailerLogoById(retailer.id)
        this.setState({ retailerLogo });
      } catch (error) {
        this.setState({ retailerLogo: '-' })
      }
    }
  };

  onIntermediaryBankAccountCreateClick = () => {
    this.setState({ isCreatingIntermediaryBankAccount: true })
  }

  onFinalBankAccountCreateClick = () => {
    this.setState({ isCreatingFinalBankAccount: true })
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state: any = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  onChangeDropDownInput = (e: any) => {
    const state: any = this.state
    state[e.target.name] = e.value
    this.setState(state)
  }

  handleFile = (file: File) => {
    if (!file) {
      return
    }
    const retailerId = this.props.retailer.id
    this.props.putRetailerLogo(retailerId, file)
  }

  // Returns the form fields of the Create Intermediary Bank Account Modal
  createIntermediaryBankAccountFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.retailer.fantasyName}</StyledTextRetailerName>,
      <TextInput
        required
        label="Nome Titular"
        key={1}
        width="100%"
        name={'depositAccountOwnerName'}
        onChange={this.onChangeInput}
        value={this.state.depositAccountOwnerName}
        errorIntent={this.state.depositAccountOwnerNameErrorIntent}
      />,
      <MaskedInput
        required
        label="Celular"
        key={2}
        width="100%"
        mask={CellphoneMaskInput}
        placeholder="(92) 99999-9999"
        name={'depositAccountOwnerPhoneNumber'}
        onChange={this.onChangeInput}
        value={this.state.depositAccountOwnerPhoneNumber}
        errorIntent={this.state.depositAccountOwnerPhoneNumberErrorIntent}
      />,
      <MaskedInput
        required
        label="CNPJ"
        mask={CnpjMaskInput}
        placeholder="00.000.000/0000-00"
        key={3}
        name={'depositAccountOwnerDocument'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.depositAccountOwnerDocument}
        errorIntent={this.state.depositAccountOwnerDocumentErrorIntent}
      />,
      <DropDownInput
        required
        style={{ border: 'none' }}
        options={['Corrente', 'Poupança']}
        label="Tipo de Conta"
        key={4}
        name={'intermediaryBankAccountAccountType'}
        width="100%"
        onChange={this.onChangeDropDownInput}
        value={this.state.intermediaryBankAccountAccountType}
      />,
      <MaskedInput
        required
        label="Número do Banco"
        key={5}
        name={'intermediaryBankAccountBankCode'}
        mask={NumberMaskInput}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.intermediaryBankAccountBankCode}
        errorIntent={this.state.intermediaryBankAccountBankCodeErrorIntent}
      />,
      <Box gap="small" key={11} direction="row-responsive">
        <MaskedInput
          required
          label="Agência"
          mask={AgencyMaskInput}
          placeholder="0000"
          key={6}
          name={'intermediaryBankAccountAgencyCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountAgencyCode}
          errorIntent={this.state.intermediaryBankAccountAgencyCodeErrorIntent}
        />
        <MaskedInput
          label="Dígito da Agência"
          mask={DigitMaskInput}
          key={7}
          placeholder="0"
          width="100%"
          name={'intermediaryBankAccountAgencyDigit'}
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountAgencyDigit}
        />
      </Box>,
      <Box gap="small" key={10} direction="row-responsive">
        <MaskedInput
          required
          label="Conta"
          mask={NumberMaskInput}
          key={8}
          name={'intermediaryBankAccountCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountCode}
          errorIntent={this.state.intermediaryBankAccountCodeErrorIntent}
        />
        <MaskedInput
          required
          label="Dígito da Conta"
          mask={DigitMaskInput}
          key={9}
          name={'intermediaryBankAccountDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountDigit}
          errorIntent={this.state.intermediaryBankAccountDigitErrorIntent}
        />
      </Box>,
    ]
  }

  // Returns the form fields of the Create Final Bank Account Modal
  createFinalBankAccountFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.retailer.fantasyName}</StyledTextRetailerName>,
      <DropDownInput
        required
        style={{ border: 'none' }}
        options={['Corrente', 'Poupança']}
        label="Tipo de Conta"
        key={1}
        name={'finalBankAccountAccountType'}
        width="100%"
        onChange={this.onChangeDropDownInput}
        value={this.state.finalBankAccountAccountType}
      />,
      <MaskedInput
        required
        label="Número do Banco"
        mask={NumberMaskInput}
        name={'finalBankAccountBankCode'}
        key={2}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.finalBankAccountBankCode}
        errorIntent={this.state.finalBankAccountBankCodeErrorIntent}
      />,
      <Box gap="small" key={8} direction="row-responsive">
        <MaskedInput
          required
          label="Agência"
          mask={AgencyMaskInput}
          placeholder="0000"
          key={3}
          name={'finalBankAccountAgencyCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountAgencyCode}
          errorIntent={this.state.finalBankAccountAgencyCodeErrorIntent}
        />
        <MaskedInput
          label="Dígito da Agência"
          mask={DigitMaskInput}
          key={4}
          name={'finalBankAccountAgencyDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountAgencyDigit}
        />
      </Box>,
      <Box gap="small" key={7} direction="row-responsive">
        <MaskedInput
          required
          label="Conta"
          mask={NumberMaskInput}
          key={5}
          name={'finalBankAccountCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountCode}
          errorIntent={this.state.finalBankAccountCodeErrorIntent}
        />
        <MaskedInput
          required
          label="Dígito da Conta"
          mask={DigitMaskInput}
          key={6}
          name={'finalBankAccountDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountDigit}
          errorIntent={this.state.finalBankAccountDigitErrorIntent}
        />
      </Box>,
    ]
  }

  // Validates the create intermediary bank account fields and, if valid, submit to API Service
  onSaveIntermediaryBankAccount = () => {
    this.setState({ isLoadingSaveIntermediaryBankAccountButton: true })

    const {
      intermediaryBankAccountAgencyCode,
      intermediaryBankAccountBankCode,
      intermediaryBankAccountCode,
      intermediaryBankAccountDigit,
      depositAccountOwnerDocument,
      depositAccountOwnerName,
      depositAccountOwnerPhoneNumber,
      intermediaryBankAccountAccountType,
      intermediaryBankAccountAgencyDigit,
    } = this.state

    let error = false
    let intermediaryBankAccountAgencyCodeErrorIntent = false,
      intermediaryBankAccountBankCodeErrorIntent = false,
      intermediaryBankAccountCodeErrorIntent = false,
      intermediaryBankAccountDigitErrorIntent = false,
      depositAccountOwnerDocumentErrorIntent = false,
      depositAccountOwnerNameErrorIntent = false,
      depositAccountOwnerPhoneNumberErrorIntent = false

    if (depositAccountOwnerName.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Nome de titular deve conter pelo menos um caracter.')
      depositAccountOwnerNameErrorIntent = true
      error = true
    } else {
      depositAccountOwnerNameErrorIntent = false
    }

    if (!validate_celular(depositAccountOwnerPhoneNumber)) {
      toaster.showErrorToast('Telefone de titular inválido.')
      depositAccountOwnerPhoneNumberErrorIntent = true
      error = true
    } else {
      depositAccountOwnerPhoneNumberErrorIntent = false
    }

    if (!validate_cnpj(depositAccountOwnerDocument)) {
      toaster.showErrorToast('CNPJ de titular inválido.')
      depositAccountOwnerDocumentErrorIntent = true
      error = true
    } else {
      depositAccountOwnerDocumentErrorIntent = false
    }

    if (intermediaryBankAccountBankCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Número de Banco deve conter pelo menos um caracter.')
      intermediaryBankAccountBankCodeErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountBankCodeErrorIntent = false
    }

    if (intermediaryBankAccountAgencyCode.length !== 4) {
      toaster.showErrorToast('Agência inválida.')
      intermediaryBankAccountAgencyCodeErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountAgencyCodeErrorIntent = false
    }

    if (intermediaryBankAccountCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Conta deve conter pelo menos um caracter.')
      intermediaryBankAccountCodeErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountCodeErrorIntent = false
    }

    if (intermediaryBankAccountDigit.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Dígito de conta deve conter um número.')
      intermediaryBankAccountDigitErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountDigitErrorIntent = false
    }

    this.setState({
      intermediaryBankAccountAgencyCodeErrorIntent,
      intermediaryBankAccountBankCodeErrorIntent,
      intermediaryBankAccountCodeErrorIntent,
      intermediaryBankAccountDigitErrorIntent,
      depositAccountOwnerDocumentErrorIntent,
      depositAccountOwnerNameErrorIntent,
      depositAccountOwnerPhoneNumberErrorIntent,
    })

    if (error) {
      this.setState({ isLoadingSaveIntermediaryBankAccountButton: false })
      return
    }

    const { retailer } = this.props

    const body: IPutRetailerIntermediaryBankAccount = {
      depositAccountOwnerDocument: depositAccountOwnerDocument
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', ''),
      depositAccountOwnerName,
      depositAccountOwnerPhoneNumber: depositAccountOwnerPhoneNumber
        .replace(' ', '')
        .replace('(', '')
        .replace(')', '')
        .replace('-', ''),
      intermediaryBankAccountAccountType:
        intermediaryBankAccountAccountType === 'Corrente' ? BankAccountType.CORRENTE : BankAccountType.POUPANCA,
      intermediaryBankAccountAgencyCode,
      intermediaryBankAccountAgencyDigit,
      intermediaryBankAccountBankCode,
      intermediaryBankAccountCode,
      intermediaryBankAccountDigit,
    }

    // TODO - Put in Redux-Saga
    bffBackofficeApiService.retailers
      .putRetailerIntermediaryBankAccount(retailer.id, body)
      .then(res => {
        this.props.fetchRetailer(retailer.id)
        toaster.showSuccessToast(`Conta Intermediária criada com sucesso.`)
        this.setState({ isLoadingSaveIntermediaryBankAccountButton: false, isCreatingIntermediaryBankAccount: false })
      })
      .catch(error => {
        toaster.showErrorToast(
          `Não foi possível criar conta intermediária. ${
            error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
          }`
        )
        this.setState({ isLoadingSaveIntermediaryBankAccountButton: false })
      })
  }

  // Validates the create final bank account fields and, if valid, submit to API Service
  onSaveFinalBankAccount = () => {
    this.setState({ isLoadingSaveFinalBankAccountButton: true })

    const {
      finalBankAccountAgencyCode,
      finalBankAccountBankCode,
      finalBankAccountCode,
      finalBankAccountDigit,
      finalBankAccountAgencyDigit,
      finalBankAccountAccountType,
    } = this.state

    let error = false
    let finalBankAccountAgencyCodeErrorIntent = false,
      finalBankAccountBankCodeErrorIntent = false,
      finalBankAccountCodeErrorIntent = false,
      finalBankAccountDigitErrorIntent = false

    if (finalBankAccountBankCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Número de Banco deve conter pelo menos um caracter.')
      finalBankAccountBankCodeErrorIntent = true
      error = true
    } else {
      finalBankAccountBankCodeErrorIntent = false
    }

    if (finalBankAccountAgencyCode.length !== 4) {
      toaster.showErrorToast('Agência deve possuir quatro números.')
      finalBankAccountAgencyCodeErrorIntent = true
      error = true
    } else {
      finalBankAccountAgencyCodeErrorIntent = false
    }

    if (finalBankAccountCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Conta deve conter pelo menos um caracter.')
      finalBankAccountCodeErrorIntent = true
      error = true
    } else {
      finalBankAccountCodeErrorIntent = false
    }

    if (finalBankAccountDigit.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Dígito de conta deve conter um número.')
      finalBankAccountDigitErrorIntent = true
      error = true
    } else {
      finalBankAccountDigitErrorIntent = false
    }

    this.setState({
      finalBankAccountAgencyCodeErrorIntent,
      finalBankAccountBankCodeErrorIntent,
      finalBankAccountCodeErrorIntent,
      finalBankAccountDigitErrorIntent,
    })

    if (error) {
      this.setState({ isLoadingSaveFinalBankAccountButton: false })
      return
    }

    const { retailer } = this.props

    const body: IPutRetailerFinalBankAccount = {
      finalBankAccountAccountType:
      finalBankAccountAccountType === 'Corrente' ? BankAccountType.CORRENTE : BankAccountType.POUPANCA,
      finalBankAccountAgencyCode,
      finalBankAccountBankCode,
      finalBankAccountCode,
      finalBankAccountDigit,
      finalBankAccountAgencyDigit,
    }

    // TODO - Put in Redux-Saga
    bffBackofficeApiService.retailers
      .putRetailerFinalBankAccount(retailer.id, body)
      .then(res => {
        this.props.fetchRetailer(retailer.id)
        toaster.showSuccessToast(`Conta Final criada com sucesso.`)
        this.setState({ isLoadingSaveFinalBankAccountButton: false, isCreatingFinalBankAccount: false })
      })
      .catch(error => {
        toaster.showErrorToast(
          `Não foi possível criar conta final. ${
            error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
          }`
        )
        this.setState({ isLoadingSaveFinalBankAccountButton: false })
      })
  }

  retailerStatusFields = () => {
    if (!this.props.retailer) {
      return []
    }
    const { status } = this.props.retailer
    if (!status) {
      return [<span key={1}>Nenhum status salvo</span>]
    }

    return [
      <Popover
        placement="top"
        style={{ width: '100%' }}
        content={<p>O varejo deve conter pelo menos uma loja cadastrada</p>}
        trigger="hover"
        key={1}
      >
        <Box justify="between" align="center" direction="row">
          <span>Lojas</span>
          <span>
            {status.stores === RETAILER_STATUS.OK ? (
              <GreenStatus>COMPLETO</GreenStatus>
            ) : (
              <RedStatus disableHover>INCOMPLETO</RedStatus>
            )}
          </span>
        </Box>
      </Popover>,
      <Popover
        placement="top"
        style={{ width: '100%' }}
        content={<p>Uma conta intermediária precisa estar cadastrada</p>}
        trigger="hover"
        key={2}
      >
        <Box justify="between" align="center" direction="row">
          <span>Conta intermediária</span>
          <span>
            {status.intermediaryBankAccount === RETAILER_STATUS.OK ? (
              <GreenStatus>COMPLETO</GreenStatus>
            ) : (
              <RedStatus>INCOMPLETO</RedStatus>
            )}
          </span>
        </Box>
      </Popover>,
      <Popover
        placement="top"
        style={{ width: '100%' }}
        content={
          <>
            <p>O CNPJ to Titular da conta intermediária precisa estar cadastrado na BMP</p>
            <p>O CNPJ é cadastrado na BMP ao cadastrar a conta intermediária</p>
          </>
        }
        trigger="hover"
        key={3}
      >
        <Box justify="between" align="center" direction="row">
          <span>Cadastro na BMP</span>
          <span>
            {status.registeredAtBmp === RETAILER_STATUS.OK ? (
              <GreenStatus>COMPLETO</GreenStatus>
            ) : (
              <RedStatus>INCOMPLETO</RedStatus>
            )}
          </span>
        </Box>
      </Popover>,
      <Popover
        placement="top"
        style={{ width: '100%' }}
        content={
          <>
            <p>Todos os parâmetros de originação devem estar cadastrados</p>
            <p>É possível cadastrar-los na aba Financeiro do Varejista</p>
          </>
        }
        trigger="hover"
        key={4}
      >
        <Box justify="between" align="center" direction="row">
          <span>Parâmetros de originação</span>
          <span>
            {status.originationsParameters === RETAILER_STATUS.OK ? (
              <GreenStatus>COMPLETO</GreenStatus>
            ) : (
              <RedStatus>INCOMPLETO</RedStatus>
            )}
          </span>
        </Box>
      </Popover>,
      <Popover
        placement="top"
        style={{ width: '100%' }}
        content={
          <>
            <p>Pelo menos uma opção de financiamento deve estar cadastrada.</p>
            <p>É possível cadastrar na seção de Risco do Backoffice</p>
          </>
        }
        trigger="hover"
        key={5}
      >
        <Box justify="between" align="center" direction="row">
          <span>Opções de financiamento</span>
          <span>
            {status.financingOptions === RETAILER_STATUS.OK ? (
              <GreenStatus>COMPLETO</GreenStatus>
            ) : (
              <RedStatus>INCOMPLETO</RedStatus>
            )}
          </span>
        </Box>
      </Popover>,
    ]
  }

  editCategories = () => {
    const { retailerSelectedCategoriesIds } = this.state
    const { retailer } = this.props
    this.props.editRetailerCategories({ categories: retailerSelectedCategoriesIds, retailerId: retailer.id })
  }

  editCategoriesFields = () => {
    const retailerCategoriesIds = this.props.retailer.categories.map(
      (categorie: IRetailerCategoryReponse) => categorie.id
    )

    return [
      <Checkbox.Group
        defaultValue={retailerCategoriesIds}
        onChange={(checkedValues: any) => {
          this.setState({ retailerSelectedCategoriesIds: checkedValues })
        }}
        key={1}
      >
        {this.props.categories.map(cat => (
          <Row key={cat.id}>
            <Col>
              <Checkbox style={{ fontSize: '20px', marginBottom: '1vh' }} value={cat.id}>
                {cat.name}
              </Checkbox>
            </Col>
          </Row>
        ))}
      </Checkbox.Group>,
    ]
  }

  enableRetailerBlockEdition = () => {
    this.setState({ enableEditBlock: true })
  }

  disableRetailerBlockEdition = () => {
    this.setState({ enableEditBlock: false })
  }

  openRetailerBlockEditionModalModal = (debtFundingPackId: string) => {
    const debtFundingPack = this.props.blocks.find(block => block.id === debtFundingPackId)

    this.setState({
      isDebtFundingPackModalOpen: true,
      debtFundingPack,
    })
  }

  closeRetailerBlockEditionModalModal = () => {
    this.setState({ isDebtFundingPackModalOpen: false })
  }

  enableRetailerIssuerEdition = () => {
    this.setState({ enableEditIssuer: true })
  }

  disableRetailerIssuerEdition = () => {
    this.setState({ enableEditIssuer: false })
  }

  handleIssuerChange = (e: any) => {
    const newIssuer = e.value
    this.setState({ newIssuer })
    this.openRetailerIssuerEditionModal(newIssuer)
  }

  openRetailerIssuerEditionModal = (newIssuer: string) => {
    if (newIssuer != this.props.retailer.issuer?.name) {
      this.setState({
        isIssuerModalOpen: true,
      })
    }
  }

  closeRetailerIssuerEditionModal = () => {
    this.setState({ isIssuerModalOpen: false })
  }

  render() {
    const {
      retailer,
      updateRetailerStatus,
      isUpdatingRetailerStatus,
      isEditRetailerCategoriesModalOpen,
      isEditRetailerCategoriesLoading,
      isPutRetailerLogoLoading,
      issuers,
      isLoadingIssuers,
    } = this.props
    const {
      isCreatingIntermediaryBankAccount,
      isCreatingFinalBankAccount,
      isLoadingSaveIntermediaryBankAccountButton,
      isLoadingSaveFinalBankAccountButton,
      isRetailerStatusModalOpen,
      enableEditBlock,
      isDebtFundingPackModalOpen,
      retailerLogo,
      enableEditIssuer,
      newIssuer,
      isIssuerModalOpen
    } = this.state

    if (!retailer) {
      return (
        <Box width="100%" height="50vh">
          <LoadingIcon />
        </Box>
      )
    }

    const retailerData: IDetailsCardData[] = [
      {
        label: 'Logo',
        value: isPutRetailerLogoLoading ? undefined : retailerLogo,
        image: true,
        handleFile: this.handleFile,
      },
      {
        label: 'ID',
        value: retailer.id,
      },
      {
        label: 'Nome',
        value: retailer.name,
      },
      {
        label: 'Apelido',
        value: retailer.fantasyName,
      },
      {
        label: 'Telefone',
        value: retailer.phoneNumber ? formatPhoneNumber(retailer.phoneNumber) : undefined,
      },
      {
        label: 'E-mail',
        value: retailer.email,
      },
      {
        label: 'CNPJ',
        value: retailer.cnpj ? formatCNPJ(retailer.cnpj) : undefined,
      },
      {
        label: 'Razão Social',
        value: retailer.businessName,
      },
      {
        label: 'Categorias',
        handleEditionClick: this.props.editRetailerCategoriesModalOpen,
        value: !retailer.categories?.length
          ? '-'
          : retailer.categories.map((c: any, idx: number) =>
              idx === retailer.categories.length - 1 ? `${c.name}.` : `${c.name}; `
            ),
      },
      {
        label: 'Status',
        value: isUpdatingRetailerStatus ? (
          <SmallLoadingIcon style={{ fill: 'black' }} />
        ) : retailer.status ? (
          retailer.status.status === RETAILER_STATUS.OK ? (
            <GreenStatus onClick={() => this.setState({ isRetailerStatusModalOpen: true })}>COMPLETO</GreenStatus>
          ) : (
            <RedStatus onClick={() => this.setState({ isRetailerStatusModalOpen: true })}>INCOMPLETO</RedStatus>
          )
        ) : (
          '--'
        ),
      },
      {
        label: 'Bloco',
        handleEditionClick: this.enableRetailerBlockEdition,
        value: (
          <BlockPicker
            value={retailer.debtFundingPack?.id}
            disabled={!enableEditBlock}
            onDropDownSelect={this.openRetailerBlockEditionModalModal}
          />
        ),
      },
      {
        label: 'Emissor do crédito',
        handleEditionClick: this.enableRetailerIssuerEdition,
        value: enableEditIssuer ? (
          <Box direction="row" align="center" height="32px">
            {isLoadingIssuers ? (
              <SmallLoadingIcon />
            ) : (
              <DropDownInput
                style={{ border: 'none', height: '32px' }}
                options={issuers?.map(issuer => issuer.name) || []}
                width="100%"
                onChange={this.handleIssuerChange}
                value={newIssuer !== undefined ? newIssuer : retailer.issuer?.name || ''}
              />
            )}
          </Box>
        ) : (
          retailer.issuer?.name || '--'
        ),
      },
    ]

    const intermediaryBankAccountData: IDetailsCardData[] = [
      {
        label: 'Tipo',
        value: retailer.intermediaryBankAccountAccountType
          ? ACCOUNT_TYPES[retailer.intermediaryBankAccountAccountType]
          : undefined,
      },
      {
        label: 'Código do Banco',
        value: retailer.intermediaryBankAccountBankCode,
      },
      {
        label: 'Conta',
        value: retailer.intermediaryBankAccountCode,
      },
      {
        label: 'Dígito da Conta',
        value: retailer.intermediaryBankAccountDigit,
      },
      {
        label: 'Agência',
        value: retailer.intermediaryBankAccountAgencyCode,
      },
      {
        label: 'Dígito da Agência',
        value: retailer.intermediaryBankAccountAgencyDigit,
      },
      {
        label: 'Titular',
        value: retailer.depositAccountOwnerName,
      },
      {
        label: 'CNPJ de Titular',
        value: retailer.depositAccountOwnerDocument ? formatCNPJ(retailer.depositAccountOwnerDocument) : undefined,
      },
      {
        label: 'Celular de Titular',
        value: retailer.depositAccountOwnerPhoneNumber
          ? formatPhoneNumber(retailer.depositAccountOwnerPhoneNumber)
          : undefined,
      },
    ]

    const finalBankAccountData: IDetailsCardData[] = [
      {
        label: 'Tipo',
        value: retailer.finalBankAccountAccountType ? ACCOUNT_TYPES[retailer.finalBankAccountAccountType] : undefined,
      },
      {
        label: 'Código do Banco',
        value: retailer.finalBankAccountBankCode,
      },
      {
        label: 'Conta',
        value: retailer.finalBankAccountCode,
      },
      {
        label: 'Dígito da Conta',
        value: retailer.finalBankAccountDigit,
      },
      {
        label: 'Agência',
        value: retailer.finalBankAccountAgencyCode,
      },
      {
        label: 'Dígito da Agência',
        value: retailer.finalBankAccountAgencyDigit,
      },
    ]

    return (
      <>
        <EditRetailerBlockModal
          isModalOpen={isDebtFundingPackModalOpen}
          onClose={this.closeRetailerBlockEditionModalModal}
          newBlock={this.state.debtFundingPack as DebtFundingPackResponse}
          oldBlock={retailer.debtFundingPack}
        />
        <EditRetailerIssuerModal
          isModalOpen={isIssuerModalOpen}
          onClose={this.closeRetailerIssuerEditionModal}
          newIssuer={newIssuer}
          oldIssuer={retailer.issuer?.name}
        />
        <FormModal
          title="Status do Varejo"
          isModalOpen={isRetailerStatusModalOpen}
          fields={this.retailerStatusFields()}
          onClose={() => this.setState({ isRetailerStatusModalOpen: false })}
          onCreate={() => {}}
          isLoadingButton={false}
          hideFooter
        />
        <FormModal
          title="Criar Conta Intermediária"
          isModalOpen={isCreatingIntermediaryBankAccount}
          fields={this.createIntermediaryBankAccountFields()}
          onClose={() => {
            this.setState({ isCreatingIntermediaryBankAccount: false })
          }}
          onCreate={this.onSaveIntermediaryBankAccount}
          isLoadingButton={isLoadingSaveIntermediaryBankAccountButton}
        />
        <FormModal
          title="Criar Conta Final"
          isModalOpen={isCreatingFinalBankAccount}
          fields={this.createFinalBankAccountFields()}
          onClose={() => {
            this.setState({ isCreatingFinalBankAccount: false })
          }}
          onCreate={this.onSaveFinalBankAccount}
          isLoadingButton={isLoadingSaveFinalBankAccountButton}
        />
        <FormModal
          title="Editar Categorias"
          isModalOpen={isEditRetailerCategoriesModalOpen}
          fields={this.editCategoriesFields()}
          onClose={this.props.editRetailerCategoriesModalClose}
          onCreate={this.editCategories}
          isLoadingButton={isEditRetailerCategoriesLoading}
        />
        <Box style={{ maxWidth: '1000px' }} direction="column" gap="large">
          <Box width="100%">
            <Box
              width="100%"
              justify="between"
              direction="row"
              align="start"
              margin={{ vertical: '0' }}
              pad={{ right: '10px' }}
            >
              <SectionTitleStyle style={{ marginBottom: 0 }}>Dados do Varejista</SectionTitleStyle>
              <StyledButton style={{ width: '120px' }} onClick={() => updateRetailerStatus(retailer.id)}>
                {isUpdatingRetailerStatus ? <SmallLoadingIcon /> : 'Atualizar Status'}
              </StyledButton>
            </Box>

            <DetailsCard data={retailerData} />
          </Box>

          <Box width="100%" align="start">
            <SectionTitleStyle>Dados da Conta</SectionTitleStyle>

            <Box width="100%" direction="row-responsive">
              <Box width="100%">
                <SubSectionTitleStyle>Intermediária</SubSectionTitleStyle>
                {retailer.intermediaryBankAccountCode ? (
                  <DetailsCard data={intermediaryBankAccountData} />
                ) : (
                  <CardStyle height="100%">
                    <Box fill align="center" justify="center" gap="small">
                      Conta Intermediária não cadastrada.
                      <StyledButton onClick={() => this.onIntermediaryBankAccountCreateClick()}>
                        Cadastrar Conta
                      </StyledButton>
                    </Box>
                  </CardStyle>
                )}
              </Box>

              <Box width="100%">
                <SubSectionTitleStyle>Final</SubSectionTitleStyle>
                {retailer.finalBankAccountCode ? (
                  <DetailsCard data={finalBankAccountData} />
                ) : (
                  <CardStyle height="100%">
                    <Box fill align="center" justify="center" gap="small">
                      Conta Final não cadastrada.
                      <StyledButton onClick={() => this.onFinalBankAccountCreateClick()}>Cadastrar Conta</StyledButton>
                    </Box>
                  </CardStyle>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  retailer: state.retailers.retailer,
  isPutRetailerLogoLoading: state.retailers.isPutRetailerLogoLoading,
  isUpdatingRetailerStatus: state.retailers.isUpdatingRetailerStatus,
  categories: state.retailers.categories,
  blocks: state.retailers.blocks,
  isEditRetailerCategoriesLoading: state.retailers.isEditRetailerCategoriesLoading,
  isEditRetailerCategoriesModalOpen: state.retailers.isEditRetailerCategoriesModalOpen,
  closeEditRetailerBlockModal: state.retailers.closeEditRetailerBlockModal,
  isEditRetailerIssuerLoading: state.retailers.isEditRetailerIssuerLoading,
  closeEditRetailerIssuerModal: state.retailers.closeEditRetailerIssuerModal,
  issuers: state.retailers.issuers,
  isLoadingIssuers: state.retailers.isLoadingIssuers,
})

const mapDispatchToProps = (dispatch: any) => ({
  resetStores: () => dispatch(StoresSliceReducer.actions.resetState()),
  fetchRetailer: (retailerId: string) => dispatch(RetailersSliceReducer.actions.fetchRetailer(retailerId)),
  resetOperators: () => dispatch(OperatorsSliceReducer.actions.resetState()),
  resetRetailerOriginationParameters: () => dispatch(RetailersSliceReducer.actions.originationsParametersReset()),
  updateRetailerStatus: (retailerId: string) =>
    dispatch(RetailersSliceReducer.actions.updateRetailerStatus(retailerId)),
  putRetailerLogo: (retailerId: string, logo: File) =>
    dispatch(RetailersSliceReducer.actions.putRetailerLogo({ retailerId, logo })),
  editRetailerCategories: (payload: EditRetailerCategoriesPayload) =>
    dispatch(RetailersSliceReducer.actions.editRetailerCategories(payload)),
  editRetailerCategoriesModalOpen: () => dispatch(RetailersSliceReducer.actions.editRetailerCategoriesModalOpen()),
  editRetailerCategoriesModalClose: () => dispatch(RetailersSliceReducer.actions.editRetailerCategoriesModalClose()),
  editRetailerIssuer: (payload: EditRetailerIssuerPayload) =>
    dispatch(RetailersSliceReducer.actions.editRetailerIssuer(payload)),
  resetEditRetailerIssuerState: () => dispatch(RetailersSliceReducer.actions.resetEditRetailerIssuerState()),
  fetchIssuers: () => dispatch(RetailersSliceReducer.actions.fetchIssuers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RetailerTab)
