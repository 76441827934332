import { StoreStatus } from './stores.res'

export interface FullAddress {
  zipCode: string
  streetName: string
  streetNumber: string
  complement?: string
  district: string
  city: string
  federativeUnit: string
  ibge?: number
}

export interface PostStoreResponse {
  id: string
  name: string
  zipcode: string
  retailerId: string
  address?: string
  googlePlaceId?: string
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  depositAccountOwnerPhoneNumber?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: string
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
  fullAddress?: FullAddress
}

export interface PostStoreRequest {
  name: string
  zipcode: string
  retailerId: string
  address?: string
  googlePlaceId?: string
  depositAccountOwnerName?: string
  depositAccountOwnerDocument?: string
  depositAccountOwnerPhoneNumber?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: string
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode?: string
  finalBankAccountAccountType?: string
  finalBankAccountAgencyCode?: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode?: string
  finalBankAccountDigit?: string
  fullAddress?: FullAddress
}

// TODO: implement directly coordinator request, this type only works because is greater than IRetailerPostReq
export interface PostRetailerRequest {
  name: string
  cnpj: string
  fantasyName: string
  businessName: string
  email: string
  phoneNumber: string
  categoriesId?: string[]
  debtFundingPackName?: DebtFundingPackName
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  depositAccountOwnerPhoneNumber?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: BankAccountType
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode?: string
  finalBankAccountAccountType?: string
  finalBankAccountAgencyCode?: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode?: string
  finalBankAccountDigit?: string
  financingOptionsType?: FinancingOptionsTypeEnum
}

export interface IRetailersResponse {
  retailers: IRetailerResponse[]
}

export interface FinancingOptionsType {
  financingOptionsType: FinancingOptionsTypeEnum
  name: string
}
export interface IRetailerResponse {
  id: string
  createdOn?: string
  modifiedOn?: string
  name: string
  fantasyName: string
  businessName?: string
  cnpj: string
  email?: string
  phoneNumber?: string
  categories: any
  debtFundingPack: DebtFundingPackResponse
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  depositAccountOwnerPhoneNumber?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: string
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode?: string
  finalBankAccountAccountType?: string
  finalBankAccountAgencyCode?: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode?: string
  finalBankAccountDigit?: string
  limitType?: LimitType
  status?: IRetailerStatusResponse
  issuer?: IssuerResponse
}

export interface IssuerResponse {
  id: string
  name: string
}

export interface DebtFundingPackResponse {
  createdOn: string
  id: string
  name: DebtFundingPackName
  uuid: string
  integrationName: string
  description: string
}

export interface IRetailerStatusResponse {
  status: RETAILER_STATUS
  intermediaryBankAccount: RETAILER_STATUS
  registeredAtBmp: RETAILER_STATUS
  originationsParameters: RETAILER_STATUS
  financingOptions: RETAILER_STATUS
  applicationPolicy: RETAILER_STATUS
  stores: RETAILER_STATUS
  limitPolicy: RETAILER_STATUS
}

export interface IStoresResponse {
  stores: IStoreResponse[]
}

export interface IStoreResponse {
  id: string
  createdOn: string
  modifiedOn: string
  name: string
  retailerId: string
  zipcode: string
  address?: string
  googlePlaceId?: string
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: BankAccountType
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode?: string
  finalBankAccountAccountType?: string
  finalBankAccountAgencyCode?: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode?: string
  finalBankAccountDigit?: string
  deactivatedOn?: string
  status: StoreStatus
  settings?: ICompanySettingsResponse
}

export interface ICompanySettingsResponse {
  captureMethod: number
  enableToGenerateBorrowerInvoice: Boolean
}

export interface IRetailerCategoriesResponse {
  categories: IRetailerCategoryReponse[]
}

export interface IRetailerCategoryReponse {
  id: string
  createdOn: string
  modifiedOn: string
  name: string
  active: boolean
  retailers?: IRetailerResponse[]
}

export interface IDebtFundingPackResponse {
  createdOn: string
  id: string
  name: DebtFundingPackName
  uuid: string
  integrationName: string
  description: string
}

export interface IPutStoreIntermediaryBankAccount {
  depositAccountOwnerName: string
  depositAccountOwnerDocument: string
  depositAccountOwnerPhoneNumber: string
  intermediaryBankAccountBankCode: string
  intermediaryBankAccountAccountType: BankAccountType
  intermediaryBankAccountAgencyCode: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode: string
  intermediaryBankAccountDigit: string
}

export interface IPutStoreFinalBankAccount {
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
}

export interface IPostGooglePlaceSearch {
  name: string
  zipcode: string
  address?: string
}

export interface IPlaceSearchResponse {
  placeId: string
  name: string
  address: string
}

export interface IPutStorePlace {
  placeId: string
}

export interface IPutRetailerIntermediaryBankAccount {
  depositAccountOwnerName: string
  depositAccountOwnerDocument: string
  depositAccountOwnerPhoneNumber: string
  intermediaryBankAccountBankCode: string
  intermediaryBankAccountAccountType: BankAccountType
  intermediaryBankAccountAgencyCode: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode: string
  intermediaryBankAccountDigit: string
}

export interface IPutRetailerFinalBankAccount {
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
}

export interface IPutRetailerCategories {
  categoryId: string[]
}

export interface DebtFundingPackResponse {
    createdOn: string;
    id: string;
    name: DebtFundingPackName;
    uuid: string;
    integrationName: string;
    description: string;
}


export enum BankAccountType {
  'POUPANCA' = "1",
  'CORRENTE' = "2"
}

export enum RETAILER_STATUS {
  INCOMPLETE_INTERMEDIARY_BANK_ACCOUNT = 'INCOMPLETE_INTERMEDIARY_BANK_ACCOUNT',
  CNPJ_NOT_AT_BMP = 'CNPJ_NOT_AT_BMP',
  INCOMPLETE_ORIGINATIONS_PARAMETERS = 'INCOMPLETE_ORIGINATIONS_PARAMETERS',
  FINANCING_OPTIONS_NOT_FOUND = 'FINANCING_OPTIONS_NOT_FOUND',
  APPLICATION_POLICY_NOT_FOUND = 'APPLICATION_POLICY_NOT_FOUND',
  STORES_NOT_FOUND = 'STORES_NOT_FOUND',
  INCOMPLETE_LIMIT_POLICIES = 'INCOMPLETE_LIMIT_POLICIES',
  OK = 'OK',
  INCOMPLETE = 'INCOMPLETE',
}

export enum FinancingOptionsTypeEnum {
  HIGH_RECURRENCE = 'HIGH_RECURRENCE',
  DEFAULT = 'DEFAULT',
}

export type LimitType = 'RECURRING_PURCHASE' | 'BIG_PURCHASE'
export type DebtFundingPackName = 'AMAZOC' | 'BRASIL' | 'ONETE';